import './App.css';
import LandingPage from './components/LandingPage';
// import './index.css';

function App() {

  return (
  <div className="">

    <LandingPage />

  </div>
  );
}

export default App;
